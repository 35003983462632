'use client'

import {useRouter} from 'next/navigation'
import {FaHistory} from 'react-icons/fa'
import {TbMenu2} from 'react-icons/tb'
import {useOverlayTriggerState} from 'react-stately'

import {SidebarDrawer} from '@/components/drawer/sidebar-drawer'
import {Logo} from '@/components/Logo'
import {Button} from '@atorie/ui/buttons'

interface FeedLayoutProps {
  children: React.ReactNode
}

function FeedLayout({children}: FeedLayoutProps) {
  const drawerOverlay = useOverlayTriggerState({})
  const router = useRouter()
  return (
    <div className="flex size-full flex-col overflow-hidden overscroll-none">
      <header className="relative flex h-12 justify-around lg:px-2">
        <div className="flex-1">
          <Button
            className="h-12"
            onPressEnd={() => drawerOverlay.open()}
            variant="ghost"
          >
            <TbMenu2 size={26} className="fill-white" />
          </Button>
        </div>
        <div className="flex h-12 justify-center">
          <Logo />
        </div>
        <div className="flex flex-1 justify-end">
          <Button
            className="h-12"
            onPressEnd={() => router.push('/search/all')}
            variant="ghost"
          >
            <FaHistory size={24} className="" />
          </Button>
        </div>
      </header>
      <SidebarDrawer
        isOpen={drawerOverlay.isOpen}
        onClose={() => drawerOverlay.close()}
      />
      {children}
    </div>
  )
}

export default FeedLayout
